import classNames from 'classnames';
import type { FC } from 'react';
import React from 'react';

import { absorbArguments } from '../../../../services/event-handler';
import type { BaseComponentProps, VoidHandler } from '../../../../types';
import { DataHook } from '../../../../types';
import styles from './SignUpWidget.scss';

interface SignUpProps extends Pick<BaseComponentProps, 'isMobile' | 't'> {
  onClick: VoidHandler;
}

const SignUp: FC<React.PropsWithChildren<SignUpProps>> = ({
  isMobile,
  t,
  onClick,
}) => {
  const handleSignUpClick = absorbArguments(onClick);

  const containerClassName = classNames(
    styles.container,
    isMobile ? styles.mobile : styles.desktop,
  );

  return (
    <div data-hook={DataHook.SignUpWidget} className={containerClassName}>
      <div className={styles.headerCont}>
        <div className={styles.header}>{t<string>('sign-up.title')}</div>
        <div className={styles.content}>{t<string>('sign-up.content')}</div>
        <div className={styles.controls}>
          <button
            data-hook={DataHook.SignUpWidgetCTA}
            type="button"
            className={classNames(styles.signUpButton)}
            onClick={handleSignUpClick}
          >
            {t<string>('sign-up.action')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
